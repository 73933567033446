<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$el.scrollIntoView({ behavior: 'smooth' });
  }
}
</script>

<style>

</style>
