<template>
<button
  type="button"
  :class="btnClass"
  @click="$modal.show(target)"
>
  <slot></slot>
</button>
</template>

<script>
export default {
  name: "btn",
  props: {
    standart: {
      type: [String],
      default: 'ac-btn',
    },
    color: {
      type: String,
      default: 'blue',
    },
    target: {
      required: true,
      type: String,
    },
    inline: {
      type: [Boolean, String],
      default: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    btnClass() {
      let c = this.standart;
      c += ' ' + this.standart + '--'+ this.color;
      c += (this.inline === true) ? (' ' + this.standart + '--inline') : '';
      return c;
    }
  }
};
</script>

<style lang="scss">
</style>
