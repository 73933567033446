<template>
  <div  class="ac-top-menu" :class="{'is-open': isOpen}" @click="toggle">
    <slot></slot>
    <portal to="button" slim>
      <a class="ac-top-menu-trigger" href="#0" @click="toggle" :class="{'is-open': isOpen}">
        <span class="ac-top-menu-icon"></span>
      </a>
    </portal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggle(e) {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }
  }
};
</script>

<style lang="scss">
.ac-top-menu {
  $icon-width: 30px;
  $icon-height: 30px;
  $icon-stripe: 4px;
  & {
    @apply fixed z-20 inset-0 flex flex-col justify-start items-center w-screen;
    background: rgba(0,0,0,.85);
    transition: transform 400ms;
    transform: translateY(-100%);

    @screen md {
      @apply static inset-auto pt-0 bg-transparent flex-row flex-1 justify-between;
      transform: translateY(0);
    }
  }

  &.is-open {
    transform: translateY(0);
  }

  &-trigger {
    @apply block relative;
    width: $icon-width;
    height: $icon-height;
    @screen md {
      @apply hidden;
    }
  }

  &-icon {
    & {
      @apply absolute z-20 w-full bg-white opacity-75;
      height: $icon-stripe;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color 300ms, opacity 300ms;

      &:hover {
        @apply opacity-100;
      }
    }

    &:before,
    &:after {
      content: "";
      @apply absolute w-full h-full bg-white;
      transition: transform 300ms, top 300ms;
    }

    &:before {
      top: $icon-height / -2 + $icon-stripe / 2;
    }

    &:after {
      bottom: $icon-height / -2 + $icon-stripe / 2;
    }
  }

  &-trigger.is-open &-icon {
    & {
      @apply bg-transparent;
    }

    &:before {
      top: 0;
      transform: rotate(135deg);
    }

    &:after {
      top: 0;
      transform: rotate(225deg);
    }
  }
}
</style>
