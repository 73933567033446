<template>
  <modal
    name="backcall"
    :adaptive="true"
    height="auto"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="p-4">
      <slot></slot>
    </div>
  </modal>
</template>

<script>
export default {
  name: "back-call",
  data() {
    return {};
  },
  methods: {
    beforeOpen() {
      document.body.classList.add('no-scroll');
    },
    beforeClose() {
      document.body.classList.remove('no-scroll');
    },
  }
};
</script>

<style lang="scss">
</style>
