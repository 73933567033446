<template>
  <div class="ac-gallery">
    <slot></slot>
  </div>
</template>

<script>
//import OtherComponent from './OtherComponent.vue'
import { tns } from "tiny-slider/src/tiny-slider";
import lightgallery from "lightgallery.js";
import 'lg-video.js';
//import "tiny-slider/dist/tiny-slider.css";

export default {
  name: "gallery",
  props: {
    autoplay: {
      required: false,
      default: true,
      type: [Boolean, String]
    },
    wraparound: {
      required: false,
      default: true,
      type: [Boolean, String]
    },
    items: {
      required: false,
      default: 1,
      type: [Number, String]
    }
  },
  mounted() {
    this.$el.addEventListener('onAfterOpen', ()=> document.body.classList.add('no-scroll') );
    this.$el.addEventListener('onCloseAfter', ()=> document.body.classList.remove('no-scroll') );
    window.lightGallery(this.$el, {
      download: false
    });

    tns({
      container: this.$el,
      items: this.items,
      autoplay: this.autoplay,
      autoplayButtonOutput: false,
      autoplayHoverPause: true,
      autoHeight: true,
      navPosition: "bottom",
      rewind: true,
      controlsText: [
        '<span class="icon-arrow-left"></span>',
        '<span class="icon-arrow-right"></span>',      ],
    });
  }
};
</script>

<style lang="scss">

</style>
