import "./bootstrap";

import Vue from "vue";
import PortalVue from "portal-vue";
import VModal from 'vue-js-modal';

import TopPinned from "./components/TopPinned.vue";
import TopMenu from "./components/TopMenu.vue";
import Accordion from "./components/Accordion";
import AccordionItem from "./components/AccordionItem";
import Carousel from "./components/Carousel.vue";
import Gallery from "./components/Gallery.vue";
import Btn from "./components/Btn.vue";
import BackCall from "./components/BackCall.vue";
import InputUpload from "./components/InputUpload.vue";
import ScrollHere from "./components/ScrollHere.vue";

Vue.use(PortalVue);
Vue.use(VModal);

window.app = new Vue({
  el: "#app",
  components: {
    TopPinned,
    TopMenu,
    Accordion,
    AccordionItem,
    Carousel,
    Gallery,
    Btn,
    BackCall,
    InputUpload,
    ScrollHere,
  },
  mounted() {
    if(window.backcall) {
      this.$modal.show('backcall');
    }
  }
});
