<template>
  <div class="ac-accordion-item">
    <header
      @click="toggle"
      class="ac-accordion-header"
      :class="[isOpen ? 'ac-accordion--open' : 'ac-accordion--border']"
    >


      <span class="icon-close ac-accordion-icon"></span>

      <span>{{ header }}</span>
    </header>
    <transition name="ac-accordion">
      <div v-show="isOpen" class="ac-accordion-body">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
//import OtherComponent from './OtherComponent.vue'

export default {
  name: "accordion-item",
  props: {
    default: {
      required: false,
      type: [Boolean, String],
      default: null
    },
    header: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isOpen: false,
      id: null
    };
  },
  methods: {
    handleToggleRequest(toggleId) {
      if (toggleId !== this.id) {
        this.isOpen = false;
        return;
      }

      this.isOpen = !this.isOpen;
    },
    toggle() {
      if (this.id) {
        this.$parent.$emit("child-clicked", this.id);
      }
    },
    setId(id) {
      this.id = id;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$parent.$emit("registered", this);
      this.$parent.$on("toggle-child", this.handleToggleRequest);
    });
  }
};
</script>

<style lang="scss">
.ac-accordion {
  &-item {
  }
  &-header {
    @apply font-bold text-xl cursor-pointer p-4 text-blue-600 border-b border-gray-300 flex items-center;
    &:hover {
      @apply text-blue-700;
    }
  }

  &-icon {
    @apply w-8 h-8 fill-current text-blue-600 mr-4 flex-shrink-0;
    font-size: 2rem;
    transition: color 150ms ease, transform 300ms ease-in-out;
  }
  &--open &-icon {
    @apply text-red-600;
    transform: rotate(225deg);
  }
  &-body {
    @apply p-4 bg-gray-200;
  }
  &--border,
  &--open {
    @apply relative;
    &:before {
      content: "";
      transition: opacity 100ms linear, transform 500ms ease-in-out;
      @apply absolute border-b border-blue-600 w-full left-0;
      bottom: -1px;
    }
    &:not(:hover)::before {
      transform: scaleX(0);
      opacity: 0;
    }
  }
  &--open::before {
    @apply border-red-600;
  }
}
.ac-accordion-enter-active {
  animation: ac-accordion 0.5s;
}
.ac-accordion-leave-active {
  animation: ac-accordion 0.5s reverse;
}
@keyframes ac-accordion {
  0% {
    transform: translateX(-100vw);
  }
  50% {
    transform: translateX(2em);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
