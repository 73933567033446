<template>
  <div>
    <input type="file" :name="name" class="w-0 h-0 overflow-hidden absolute z-0" :id="id" multiple @change="changed">
    <label :for="id" class="ac-btn ac-btn--yellow w-full mx-0">

      <span v-if="filename == ''">
        <span class="icon-upload inline-block"></span>
        <slot></slot>
      </span>
      <span v-else v-text="filename"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      required: true,
      type: String
    },
    id: {
      required: true,
      type: String
    },
    caption: {
      type: String,
      default: "Выбрано файлов: ",
    }
  },
  data() {
    return {
      filename: ''
    }
  },
  methods: {
    changed( e ) {

      console.log(e.target.value);
      if (e.target.files && e.target.files.length > 1) {
        this.filename = this.caption + e.target.files.length;
      } else {
        this.filename = e.target.value.split( '\\' ).pop();
      }
    }
  }
}
</script>

<style>

</style>
