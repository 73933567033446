<template>
  <div class="top-pinned">
    <slot></slot>
  </div>
</template>

<script>
import { throttle } from "lodash";

export default {
  data() {
    return {
      isPinned: false,
      originalOffsetTop: 0
    };
  },
  methods: {
    handleScroll() {
      if (window.scrollY >= this.originalOffsetTop) {
        //this.$el.classList.remove("not-pinned");
        document.body.classList.remove("not-pinned");
      } else {
        //this.$el.classList.add("not-pinned");
        document.body.classList.add("not-pinned");
      }
    }
  },
  mounted() {
    this.originalOffsetTop = this.$el.offsetTop;

    window.addEventListener("scroll", throttle(this.handleScroll, 100));
  }
};
</script>

<style lang="scss">
@import "../../scss/components/functions";
.not-pinned {
  & main {
    margin-top: 0;
    //margin-top: calculateRem(40px);
  }

  .top-pinned {
    @apply static top-auto z-auto;
  }
}

.top-pinned {
  @apply fixed w-full z-20 top-0;
}

main {
  margin-top: calculateRem(96px); // Высота навигационной панели
}
</style>>

