<template>
  <div class="ac-accordion">
    <slot></slot>
  </div>
</template>

<script>
//import OtherComponent from './OtherComponent.vue'

export default {
  name: "accordion",
  data() {
    return {
      next_id: 1
    };
  },
  methods: {
    getNextId() {
      const v = this.next_id.toString(10);
      this.next_id++;
      return v;
    },
    handleChildClicked(child_id) {
      console.log(child_id);
      this.$emit("toggle-child", child_id);
    }
  },
  mounted() {
    this.$on('registered', (child) => {
      const id = this.getNextId();
      child.setId(id);
    });
    this.$on('child-clicked', this.handleChildClicked);
  }

};
</script>

<style lang="scss">
.ac-accordion {
  @apply bg-white shadow-lg overflow-hidden border border-gray-200;
}
</style>
